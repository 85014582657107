import React from 'react';
import { render } from 'react-dom';
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { createGenerateClassName, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { forEach } from 'lodash';

import activityInitializer from './initializers/activity';
import navigationManifest from './pages/navigation';
import Timeout from './pages/timeout';
import ShadowConfirmation from './pages/shadow-confirmation';
import { attemptVerifyUser, verifyUser } from './state/user';
import { NoEulaError } from './lib/errors';
import CoreVitalFaq from './pages/core-vital-faq';
import CoreVitalHowToVideo from './pages/core-vital-how-to-video';
import CoreVitalPrivacyPolicy from './pages/core-vital-privacy-policy';
import CoreVitalReleaseNotes from './pages/core-vital-release-notes';
import CoreVitalTermsAndConditions from './pages/core-vital-terms-and-conditions';
import CoreVitalHowToUse from './pages/core-vital-how-to-use';
import CoreVitalUserManual from './pages/core-vital-user-manual';
import CoreVitalUserManualMenu from './pages/core-vital-user-manual-menu';
import CoreVitalTurnOnPermissions from './pages/core-vital-turn-on-permissions';
import SessionConflict from './pages/session-conflict';
import InvalidPage from './pages/invalid-page';
import InfoUpdatedSuccessfully from './pages/info-updated-successfully';
import NotificationIntro from './components/notification-intro';
import LicenseError from './pages/license-error';

import store from './store';
import brand from './lib/brand';

import './index.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'patient-app',
});

activityInitializer();
function requireAuth(nextState, replace, cb) {
  return store.dispatch(verifyUser())
    .then(() => cb())
    .catch((err) => {
      if (err instanceof NoEulaError) {
        replace({ pathname: '/signup_eula' });
      } else {
        replace({
          pathname: '/login',
          query: {
            ...nextState.location.query,
            originalPath: nextState.location.pathname,
          },
        });
      }
      cb();
    });
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
});

function attemptAuth(nextState, replace, cb) {
  return store.dispatch(attemptVerifyUser())
    .then(() => cb());
}

const mainRoutes = [
  { path: '/notification-success/:notificationType', component: InfoUpdatedSuccessfully },
  { path: '/notifications/:notificationId', component: ShadowConfirmation, noauth: true }, // Handles Text based notifications
  { path: '/core-vital-user-manual-menu', component: CoreVitalUserManualMenu },
  { path: '/core-vital-how-to-video', component: CoreVitalHowToVideo },
  { path: '/core-vital-faq', component: CoreVitalFaq },
  { path: '/core-vital-terms-and-conditions', component: CoreVitalTermsAndConditions },
  { path: '/core-vital-how-to-use', component: CoreVitalHowToUse },
  { path: '/core-vital-user-manual', component: CoreVitalUserManual },
  { path: '/core-vital-privacy-policy', component: CoreVitalPrivacyPolicy },
  { path: '/core-vital-release-notes', component: CoreVitalReleaseNotes },
  { path: '/core-vital-turn-on-permissions', component: CoreVitalTurnOnPermissions },
  { path: '/timeout', component: Timeout },
  { path: '/session-conflict', component: SessionConflict },
  { path: '/invalid-page', component: InvalidPage },
  { path: '/license-error', component: LicenseError },
];

const routes = [];
function getAuth(page) {
  if (page.attemptAuth) {
    return attemptAuth;
  }
  if (page.noauth) {
    return null;
  }
  return requireAuth;
}
forEach(navigationManifest, (track, key) => {
  forEach(track.pages, (page, innerKey) => {
    routes.push(<Route {...page} onEnter={getAuth(page)} track={key} key={page.path + innerKey} />);
  });
});

forEach(mainRoutes, (route) => {
  routes.push(<Route {...route} key={route.path} />);
});

routes.push(<Route path="/notification-intro" component={NotificationIntro} isPro={true} key="/notification-intro" />);

window.browserHistory = browserHistory;

render(
  (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={brand.nextTheme}>
          <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
            {routes}
          </Router>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  ), document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(() => {
      // Registration was successful
    }, (err) => {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
