import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { CircleExclamationSolid } from '../lib/icons';
import { throttledReset } from '../initializers/activity';
import { removeProInNewTabListener } from '../lib/pro-in-new-tab';
import { clearPros } from '../state/pro-forms';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
    '& path': {
      fill: colors.errorRed,
    },
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: desktopMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    textAlign: 'center',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

class LicenseError extends React.Component {
  constructor(props) {
    super(props);

    this.props.clearPros();

    this.backButtonHandler = () => {
      window.history.go(1);
    };
    window.addEventListener('popstate', this.backButtonHandler, true);

    this.activityTimer = setInterval(throttledReset, 10000);
    removeProInNewTabListener();
  }

  componentWillUnmount = () => {
    clearInterval(this.activityTimer);
    window.removeEventListener('popstate', this.backButtonHandler, true);
  }

  render() {
    const { classes, router } = this.props;

    let message = 'The required vitals for the app have not been enabled.';
    
    if (router.location.state && router.location.state.licenseUnauthorized) {
      message = 'There is not an active licence key to use Informed Vital Core.';
    }

    return (
      <div className={classes.page}>
        <div className={classes.pageContent}>
          <div className={classes.exclamationWrapper}>
            <CircleExclamationSolid />
          </div>
          <p><strong>License Error</strong></p>
          <p>{message}</p>
          <p>Please <strong>CLOSE</strong> this browser window.</p>
        </div>
      </div>
    );
  }
}

LicenseError.propTypes = {
  classes: PropTypes.object.isRequired,
  clearPros: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default connect(null, { clearPros })(withStyles(styles)(LicenseError));
